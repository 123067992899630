import axios from 'axios'
import { CreateLead } from '../../@types'
import { LOAN_SERVICE_URL } from '../../consts'

const createLead: CreateLead = async (firstName, lastName, email, accessToken) => {

  const response = await axios.post(`${LOAN_SERVICE_URL}/lead `, {
    firstName,
    lastName,
    email,
  }, {
    headers: {
      authorization: `Bearer ${accessToken}`
    }
  })

  if (response.status !== 201 && response.status !== 200) {
    throw new Error('lead was not created')
  }

  const { leadId } = response.data

  return leadId
}

export default createLead
