const parentComms = (
  source: 'journey',
  type: 'close',
): void => {
  switch (type) {
  case 'close': {
    window.parent?.postMessage({ source, type }, '*')
    break
  }
  default: {
    break
  }
  }
}

export default parentComms
