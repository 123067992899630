import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#0f223c',
    },
    secondary: {
      main: '#0f223c',
    },
    background: {
      default: 'transparent',
    },
  },
  typography: {
    h6: {
      color: '#0f223c',
    },
    fontFamily: [
      'Fb Matritsa Narrow',
      'Heebo',
      'sans-serif'
    ].join(','),
  },
})

export default theme