import axios from 'axios'
import { GetAccount } from '../../@types'
import { LOAN_SERVICE_URL } from '../../consts'

const getAccount: GetAccount = async (accessToken) => {

  const response = await axios.get(`${LOAN_SERVICE_URL}/account `, {
    headers: {
      authorization: `Bearer ${accessToken}`
    }
  })

  if (response.status !== 200) {
    throw new Error('lead was not updated')
  }

  return response.data
}

export default getAccount
