/* eslint-disable max-len */
import React, { useContext } from 'react'
import { FormControl, InputAdornment, InputLabel, Select, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FeaturedVideoOutlined, DriveFileRenameOutlineOutlined } from '@mui/icons-material'
import { JourneyContext } from '../../store/JourneyContext/JourneyContext'
import Button from '../generic/Button'
import Container from '../generic/Container/Container'
import handleInputWithRestrictions from '../../helpers/restrictUnwantedInputs'
import BusinessInfoLogic from './BusinessInfoLogic'

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    placeContent: 'space-between',
  },
  textFieldNumber: {
    '& .MuiOutlinedInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
}), { name: 'BusinessInfo' })

const BusinessInfo = (): JSX.Element => {
  const classes = useStyles()

  const {
    businessType,
    setBusinessType,
    businessId,
    setBusinessId,
    nationalId,
    setNationalId,
    businessName,
    setBusinessName,
    setActivityArea,
    activityArea,
    geoArea,
    setGeoArea,
    isLoading,
  } = useContext(JourneyContext)

  const {
    renderActivityAreas,
    renderGeoAreas,
    renderIncorporationType,
    handleMoveToNextScreen,
  } = BusinessInfoLogic()

  return (
    <Container>
      <Typography variant='h5' fontWeight='bold' alignSelf='center' padding={3}>
        פרטי העסק
      </Typography>
      <form
        className={classes.form}
        onSubmit={handleMoveToNextScreen}
        onInvalid={({ target }) => (target as HTMLInputElement).setCustomValidity('אנא מלא את השדה הזה')}
        onInput={({ target }) => (target as HTMLInputElement).setCustomValidity('')}
      >
        <div>
          <FormControl fullWidth margin='dense'>
            <InputLabel id="credit-type-select-label" required>סוג התאגדות</InputLabel>
            <Select
              labelId="credit-type-select-label"
              id="credit-type-select"
              value={businessType}
              label="סוג התאגדות"
              onChange={({ target }) => setBusinessType(target.value)}
              required
            >
              {renderIncorporationType}
            </Select>
          </FormControl>

          {(businessType === 'עוסק מורשה') && (
            <>
              <TextField
                label='ת.ז'
                value={nationalId}
                onChange={({ target }) => handleInputWithRestrictions(target.value, setNationalId)}
                type='number'
                fullWidth
                margin='dense'
                className={classes.textFieldNumber}
                required
                autoComplete='off'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FeaturedVideoOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </>
          )}
          {(businessType === 'נותן שירותי אשראי' || businessType === 'חברה') && (
            <TextField
              label='ח.פ'
              required
              value={businessId}
              onChange={({ target }) => handleInputWithRestrictions(target.value, setBusinessId)}
              type='number'
              fullWidth
              margin='dense'
              autoComplete='off'
              className={classes.textFieldNumber}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FeaturedVideoOutlined />
                  </InputAdornment>
                ),
              }}
            />
          )}
          {(businessType === 'נותן שירותי אשראי' || businessType === 'חברה' || businessType === 'עוסק מורשה') && (
            <>
              <TextField
                required
                label='שם בית העסק'
                value={businessName}
                onChange={({ target }) => handleInputWithRestrictions(target.value, setBusinessName)}
                fullWidth
                margin='dense'
                autoComplete='off'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <DriveFileRenameOutlineOutlined />
                    </InputAdornment>
                  ),
                }}
              />
              <FormControl fullWidth margin='dense'>
                <InputLabel id="activity-area-select-label" required>תחום פעילות</InputLabel>
                <Select
                  labelId="activity-area-select-label"
                  id="activity-area-select"
                  value={activityArea}
                  label="תחום פעילות"
                  onChange={({ target }) => setActivityArea(target.value)}
                  required
                >
                  {renderActivityAreas}
                </Select>
              </FormControl>
              <FormControl fullWidth margin='dense'>
                <InputLabel id="geo-area-select-label" required>אזור גיאוגרפי</InputLabel>
                <Select
                  labelId="geo-area-select-label"
                  id="geo-area-select"
                  value={geoArea}
                  label="אזור גיאוגרפי"
                  onChange={({ target }) => setGeoArea(target.value)}
                  required
                >
                  {renderGeoAreas}
                </Select>
              </FormControl>
            </>
          )}
        </div>
        <div>
          <Button title='המשך' type='submit' isLoading={isLoading} />
        </div>
      </form>
    </Container>
  )
}

export default BusinessInfo