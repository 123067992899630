import React from 'react'
import './index.css'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import AppProviders from './AppProviders'

const container = document.getElementById('root') as HTMLElement
const root = ReactDOM.createRoot(container)

root.render(<AppProviders />)

reportWebVitals()
