/* eslint-disable max-len */
import React, { useContext } from 'react'
import { InputAdornment, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AlignVerticalBottomOutlined } from '@mui/icons-material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { JourneyContext } from '../../store/JourneyContext/JourneyContext'
import Button from '../generic/Button'
import Container from '../generic/Container/Container'
import handleInputWithRestrictions from '../../helpers/restrictUnwantedInputs'
import BusinessInfoLogic from './BusinessInfoLogic'

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    placeContent: 'space-between',
  },
  textFieldNumber: {
    '& .MuiOutlinedInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
}), { name: 'BusinessInfoSecond' })

const BusinessInfoSecond = (): JSX.Element => {
  const classes = useStyles()

  const {
    predictedCurrentYearIncome,
    setPredictedCurrentYearIncome,
    lastYearIncome,
    setLastYearIncome,
    businessEstablishedDate,
    setEstablishmentDate,
    isLoading,
  } = useContext(JourneyContext)

  const {
    handleContinue,
  } = BusinessInfoLogic()

  return (
    <Container>
      <Typography variant='h5' fontWeight='bold' alignSelf='center' padding={3}>
        פרטי העסק
      </Typography>
      <form
        className={classes.form}
        onSubmit={handleContinue}
        onInvalid={({ target }) => (target as HTMLInputElement).setCustomValidity('אנא מלא את השדה הזה')}
        onInput={({ target }) => (target as HTMLInputElement).setCustomValidity('')}
      >
        <div>
          <TextField
            required
            label='מחזור הכנסות שנתי צפוי בשנה הנוכחית'
            value={Number(predictedCurrentYearIncome).toLocaleString()}
            onChange={({ target }) => !isNaN(+target.value.replace(/,/g, '')) ? handleInputWithRestrictions(target.value.replace(/,/g, ''), setPredictedCurrentYearIncome) : undefined}
            fullWidth
            margin='dense'
            className={classes.textFieldNumber}
            InputProps={{
              startAdornment: (<Typography sx={{ paddingRight: 1 }}>
                ₪
              </Typography>),
              endAdornment: (
                <InputAdornment position="end">
                  <AlignVerticalBottomOutlined />
                </InputAdornment>
              )
            }}
          />
          <TextField
            required
            label='מחזור הכנסות שנתי בשנה הקודמת'
            value={Number(lastYearIncome).toLocaleString()}
            onChange={({ target }) => !isNaN(+target.value.replace(/,/g, '')) ? handleInputWithRestrictions(target.value.replace(/,/g, ''), setLastYearIncome) : undefined}
            fullWidth
            margin='dense'
            autoComplete='off'
            className={classes.textFieldNumber}
            InputProps={{
              startAdornment: (<Typography sx={{ paddingRight: 1 }}>
                ₪
              </Typography>),
              endAdornment: (
                <InputAdornment position="end">
                  <AlignVerticalBottomOutlined />
                </InputAdornment>
              )
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="תאריך הקמת העסק"
              value={businessEstablishedDate}
              onChange={(newDate) => setEstablishmentDate(newDate)}
              renderInput={(params) => (<TextField {...params} fullWidth margin='dense' required InputLabelProps={{ shrink: true }} autoComplete='off' />)}
              inputFormat="MM/yyyy"
              mask="__/____"
              maxDate={new Date()}
            />
          </LocalizationProvider>
        </div>
        <div>
          <Button title='המשך' type='submit' isLoading={isLoading} />
        </div>
      </form>
    </Container >
  )
}

export default BusinessInfoSecond