import { StepContext } from '@open-finance-ai/components'
import React, { useContext } from 'react'
import { MenuItem } from '@mui/material'
import toast from 'react-hot-toast'
import { JourneyContext } from '../../store/JourneyContext/JourneyContext'
import { updateLead, getAccount } from '../../api'
import isValidNationalID from '../../helpers/isValidNationalID'

function CreditInfoLogic(): any {
  const {
    setStep
  } = useContext(StepContext)

  const {
    leadId,
    businessType,
    businessId,
    nationalId,
    businessName,
    businessEstablishedDate,
    setIsLoading,
    lastYearIncome,
    predictedCurrentYearIncome,
    accessToken,
    activityArea,
    geoArea,
  } = useContext(JourneyContext)

  const incorporationTypes = [
    'עוסק מורשה',
    'חברה',
    'נותן שירותי אשראי',
  ]

  const activityAreas = [
    'אנרגיה כימקלים ודלק',
    'אספקת כוח אדם',
    'בניה',
    'הובלות ולוגיסטיקה',
    'חקלאות וגינון',
    'מזון ומסעדנות',
    'מפעלים ותעשיה',
    'פיננסים',
    'רכב',
    'שירותים',
    'תשתיות',
    'אחר',
  ]

  const geoAreas = [
    'צפון',
    'שרון',
    'תל אביב',
    'מרכז',
    'שפלה',
    'דרום',
    'ירושלים',
    'יהודה ושומרון',
  ]

  const renderIncorporationType = incorporationTypes.map((type) => (
    <MenuItem
      value={type}
      key={type}
    > {type}
    </MenuItem>
  ))

  const renderActivityAreas = activityAreas.map((type) => (
    <MenuItem
      value={type}
      key={type}
    > {type}
    </MenuItem>
  ))

  const renderGeoAreas = geoAreas.map((type) => (
    <MenuItem
      value={type}
      key={type}
    > {type}
    </MenuItem>
  ))

  const handleMoveToNextScreen = (e: React.FormEvent): void => {
    e.preventDefault()
    if (nationalId && !isValidNationalID(nationalId)) {
      toast.error('תעודת זהות לא תקינה')

      return
    }
    
    setStep((step) => step + 1)
  }

  const handleContinue = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()
    try {
      setIsLoading(true)

      const updatedLead = {
        businessType,
        businessId,
        nationalId,
        businessName,
        businessEstablishedDate,
        predictedCurrentYearIncome,
        lastYearIncome,
        activityArea,
        geoArea,
      } as Record<string, any>

      const getAccountData = await getAccount(accessToken)

      if (getAccountData.account) await updateLead(leadId, accessToken, { isUserExists: true })

      Object.keys(updatedLead).forEach((key: string) => {
        if (updatedLead[key] == null || updatedLead[key] === '') {
          delete updatedLead[key]
        }
      })

      const isSuccess = await updateLead(leadId, accessToken, updatedLead)

      if (!isSuccess) {
        throw new Error('')
      }

      setStep((step) => step + 1)
    } catch (e) {
      toast.error('שגיאה לא ידועה, לא ניתן להמשיך בתהליך כרגע')
    } finally {
      setIsLoading(false)
    }
  }

  return {
    renderActivityAreas,
    renderGeoAreas,
    renderIncorporationType,
    handleContinue,
    handleMoveToNextScreen,
  }
}

export default CreditInfoLogic