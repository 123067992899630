/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { StepContext } from '@open-finance-ai/components'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { createLead, sendOtpViaSms, verifyOtpFromSms } from '../../api'
import { JourneyContext } from '../../store/JourneyContext/JourneyContext'

const OTPLogic = () => {
  const [otp, setOtp] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { increment } = useContext(StepContext)

  const {
    firstName,
    lastName,
    email,
    phone,
    setLeadId,
    setAccessToken,
  } = useContext(JourneyContext)

  const handleSendOTP = useCallback(async (): Promise<void> => {
    try {
      setIsLoading(true)
      setError('')

      await sendOtpViaSms(phone)
    }
    catch (e) {
      const status = e instanceof AxiosError ? e.response?.status : undefined

      if (status == 429) {
        setError('הקוד נחסם בשל ניסיונות רבים, בקש/י קוד חדש בעוד מספר דקות')
      }
      else {
        setError('שליחת הקוד נכשלה')
      }
    }
    finally {
      setIsLoading(false)
    }
  }, [phone, setIsLoading])

  useEffect(() => {
    handleSendOTP()
  }, [handleSendOTP])

  const handleSubmitOTP = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()
    try {
      setIsLoading(true)
      setError('')

      const data = await verifyOtpFromSms(phone, otp)

      if (!data) {
        throw new Error('האימות נכשל, נסה שנית')
      }

      setAccessToken(data.accessToken)

      try {
        const leadId = await createLead(firstName, lastName, email, data.accessToken)
  
        setLeadId(leadId)
  
        increment()
      } catch (e) {
        if (e instanceof AxiosError && e.response?.status === 409) {
          setError('כבר קיים לך פרופיל עם פרטים זהים, פנה אלינו בטלפון לקבלת עזרה ')
        } else {
          setError('קרתה שגיאה, אנא נסה שנית. במידה והתקלה נמשכת, פנה אלינו בטלפון')
        }
      }

    }
    catch (e) {
      const status = e instanceof AxiosError ? e.response?.status : undefined

      if (status == 429) {
        setError('הגעת לניסיונות בדיקה מקסימליים, בקש קוד חדש')
      }
      else {
        setError('האימות נכשל, נסה שנית')
      }

    }
    finally {
      setIsLoading(false)
    }
  }

  return {
    otp,
    setOtp,
    handleSendOTP,
    handleSubmitOTP,
    error,
    isLoading,
  }
}

export default OTPLogic