/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react'

function ProcessCompletedLogic() {

  const handleClose = (e: React.FormEvent): void => {
    e.preventDefault()

    window.close()
    
    window.location.href = 'https://michman-loans.co.il/'
  }

  return {
    handleClose,
  }
}

export default ProcessCompletedLogic