/* eslint-disable max-len */
import { Typography, TextField, InputAdornment } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useContext } from 'react'
import { AccountCircleOutlined, PhoneIphoneOutlined, EmailOutlined } from '@mui/icons-material'
import { JourneyContext } from '../../store/JourneyContext/JourneyContext'
import Button from '../generic/Button'
import Container from '../generic/Container/Container'
import handleInputWithRestrictions from '../../helpers/restrictUnwantedInputs'
import UserPersonalInfoLogic from './UserPersonalInfoLogic'

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    placeContent: 'space-between',
  },
}), { name: 'UserPersonalInfoForm' })

const UserPersonalInfoForm = (): JSX.Element => {
  const classes = useStyles()

  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    phone,
    setPhone,
    email,
    setEmail,
    isLoading,
  } = useContext(JourneyContext)

  const {
    handleContinue,
  } = UserPersonalInfoLogic()

  return (
    <Container>
      <Typography variant='h5' textAlign='center' padding={1}>
        לבדיקת זכאותך יש למלא את הפרטים הבאים
      </Typography>
      <Typography variant='h5' fontWeight='bold' alignSelf='center' padding={2}>
        פרטים אישיים
      </Typography>
      <form
        className={classes.form}
        onSubmit={handleContinue}
      >
        <div>
          <TextField
            label='שם פרטי'
            value={firstName}
            onChange={({ target }) => handleInputWithRestrictions(target.value, setFirstName)}
            fullWidth
            margin='dense'
            required
            id='firstName'
            autoComplete='off'
            onInvalid={({ target }) => (target as HTMLInputElement).setCustomValidity('אנא מלא את השדה הזה')}
            onInput={({ target }) => (target as HTMLInputElement).setCustomValidity('')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AccountCircleOutlined />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label='שם משפחה'
            value={lastName}
            onChange={({ target }) => handleInputWithRestrictions(target.value, setLastName)}
            fullWidth
            margin='dense'
            required
            id='lastName'
            autoComplete='off'
            onInvalid={({ target }) => (target as HTMLInputElement).setCustomValidity('אנא מלא את השדה הזה')}
            onInput={({ target }) => (target as HTMLInputElement).setCustomValidity('')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AccountCircleOutlined />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label='טלפון'
            value={phone}
            onChange={({ target }) => handleInputWithRestrictions(target.value, setPhone)}
            fullWidth
            margin='dense'
            type='tel'
            required
            id='phone'
            autoComplete='off'
            onInvalid={({ target }) => (target as HTMLInputElement).setCustomValidity('אנא הכנס מספר טלפון תקין')}
            onInput={({ target }) => (target as HTMLInputElement).setCustomValidity('')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PhoneIphoneOutlined />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label='דוא״ל'
            value={email}
            onChange={({ target }) => setEmail(target.value)}
            fullWidth
            margin='dense'
            type='email'
            required
            id='email'
            autoComplete='off'
            sx={{ input: { direction: 'ltr' } }}
            onInvalid={({ target }) => (target as HTMLInputElement).setCustomValidity('אנא מלא כתובת דוא״ל תקינה')}
            onInput={({ target }) => (target as HTMLInputElement).setCustomValidity('')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EmailOutlined />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div>
          <Button title='המשך' type='submit' isLoading={isLoading} />
        </div>
      </form>
    </Container >
  )
}

export default UserPersonalInfoForm