/* eslint-disable @typescript-eslint/explicit-function-return-type */

const CreditRejectLogic = () => {

  const handleClose = (): void => {
    window.close()
    window.location.href = 'https://michman-loans.co.il/'
  }

  return {
    handleClose,
  }
}

export default CreditRejectLogic