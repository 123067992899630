import { StepContext } from '@open-finance-ai/components'
import React, { useContext, useMemo } from 'react'
import { MenuItem } from '@mui/material'
import toast from 'react-hot-toast'
import { updateLead } from '../../api'
import { JourneyContext } from '../../store/JourneyContext/JourneyContext'

function CreditInfoLogic(): any {
  const { setStep } = useContext(StepContext)

  // eslint-disable-next-line max-len
  const { leadId, creditType, collateralType, otherCollateralType, creditPurpose, creditRequested, creditTerm, setIsLoading, accessToken } = useContext(JourneyContext)

  const creditTypes = [
    {
      key: 'collateral',
      label: 'הלוואה מגובה בטוחה',
    },
    {
      key: 'nonCollateral',
      label: 'הלוואה ללא בטוחה',
    },
    {
      key: 'cheques',
      label: 'ניכיון שיקים',
    },
  ]

  const collateralTypes = [
    {
      key: 'realEstate',
      label: 'נדל"ן',
    },
    {
      key: 'vehicles',
      label: 'רכב',
    },
    {
      key: 'mechanics',
      label: 'כלי צמ"ה',
    },
    {
      key: 'other',
      label: 'אחר',
    },
  ]

  const creditPurposes = [
    {
      key: 'investment',
      label: 'השקעה',
    },
    {
      key: 'returningFortune',
      label: 'הון חוזר',
    },
    {
      key: 'refinance',
      label: 'מיחזור אשראי קיים',
    },
  ]

  const renderCreditType = creditTypes.map((obj) => (
    <MenuItem key={obj.key} value={obj.key}>
      {' '}
      {obj.label}
    </MenuItem>
  ))

  const renderCollateralTypes = collateralTypes.map((obj) => (
    <MenuItem key={obj.key} value={obj.key}>
      {' '}
      {obj.label}
    </MenuItem>
  ))

  const renderCreditPurpose = creditPurposes.map((obj) => (
    <MenuItem key={obj.key} value={obj.key}>
      {' '}
      {obj.label}
    </MenuItem>
  ))


  const getMonthValue = (value: number): string => {
    if (value === 1) {
      return 'חודש'
    } else if (value === 2) {
      return 'חודשיים'
    } else {
      return `${value} חודשים`
    }
  }

  const handleContinue = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()
    try {
      setIsLoading(true)

      if (creditType !== 'cheques' && Number(creditRequested) < 150000) {
        toast.error('סכום האשראי המבוקש נמוך מהמינימום')

        return
      }

      const isSuccess = await updateLead(leadId, accessToken, {
        collateralType: collateralTypes.find((type) => type.key === collateralType)?.label,
        otherCollateralType: otherCollateralType !== '' ? otherCollateralType : undefined,
        creditType: creditTypes.find((type) => type.key === creditType)?.label,
        creditPurpose: creditPurposes.find((type) => type.key === creditPurpose)?.label,
        creditRequested: creditRequested !== '' ? creditRequested : undefined,
        creditTerm: creditTerm !== '' ? creditTerm : undefined,
      })

      if (!isSuccess) {
        throw new Error('')
      }

      setStep((step) => step + 1)
    } catch (e) {
      toast.error('שגיאה לא ידועה, לא ניתן להמשיך בתהליך כרגע')
    } finally {
      setIsLoading(false)
    }
  }

  const creditTermsSliderMarks = useMemo(
    () => [
      {
        value: 1,
        label: 'חודש',
      },
      (collateralType !== 'realEstate') && {
        value: 36,
        label: '36 חודשים',
      },
      {
        value: 60,
        label: '60 חודשים',
      },
    ],
    [collateralType]
  )

  return {
    renderCreditType,
    renderCollateralTypes,
    renderCreditPurpose,
    getMonthValue,
    handleContinue,
    creditTermsSliderMarks,
  }
}

export default CreditInfoLogic
