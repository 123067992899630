import React from 'react'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'
import completeIcon from '../../assets/images/complete.png'
import Button from '../generic/Button'
import Container from '../generic/Container/Container'
import ProcessCompletedLogic from './ProcessCompletedLogic'

const useStyles = makeStyles(
  () => ({
    form: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      width: '100%',
      placeContent: 'space-between',
    },
    imageContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
  { name: 'ProcessCompleted' }
)

const ProcessCompleted = (): JSX.Element => {
  const classes = useStyles()
  const { handleClose } = ProcessCompletedLogic()

  return (
    <Container>
      <Typography variant='h5' fontWeight='bold' alignSelf='center' textAlign='center' padding={3}>
        תהליך הגשת הבקשה הסתיים בהצלחה
      </Typography>

      <form
        className={classes.form}
        onSubmit={handleClose}
      >
        <Typography textAlign='center'>
          תודה שבחרת לפנות למיכמן.
          <br />
          נציגנו בוחנים את הבקשה ויצרו איתך קשר בהקדם האפשרי.
          <br />
          צוות מיכמן
        </Typography>

        <div className={classes.imageContainer}>
          <img src={completeIcon} alt='complete-logo' width={150} />
        </div>

        <div dir='ltr' style={{ display: 'flex', flexDirection: 'column' }}>
          <Button title='סיום' type='button' style={{ width: '100%' }} onClick={() => window.location.href = 'https://michman-loans.co.il/'} />
        </div>
      </form>
    </Container>
  )
}

export default ProcessCompleted
