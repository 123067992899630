import React from 'react'
import { DialogWrapper, StepContext } from '@open-finance-ai/components'
import { JourneyContext } from '../../store/JourneyContext/JourneyContext'
import UserPersonalInfo from '../../components/UserPersonalInfo'
import JourneyContextLogic from '../../store/JourneyContext/JourneyContextLogic'
import CreditInfo from '../../components/CreditInfo'
import BusinessInfo from '../../components/BusinessInfo'
import BusinessInfoSecond from '../../components/BusinessInfo/BusinessInfoSecond'
import CreditReject from '../../components/CreditReject'
import BusinessAdditionInfo from '../../components/BusinessAdditionInfo'
import ProcessCompleted from '../../components/ProcessCompleted'
import OTP from '../../components/OTP/OTP'
import parentComms from '../../helpers/parentComms'
import HomeLogic from './HomeLogic'

const logoUrl = 'https://open-finance-assets.s3.eu-west-1.amazonaws.com/customers-logos/michman-current.png'

const Home = (): JSX.Element => {
  const { JourneyContextValue } = JourneyContextLogic()

  const { dialogStyle } = HomeLogic()

  return (
    <JourneyContext.Provider value={JourneyContextValue}>
      <DialogWrapper
        numberOfPhases={0}
        preventBackAtSteps={[6]}
        onClickClose={() => parentComms('journey', 'close')}
        style={dialogStyle}
        Image={() => <img src={logoUrl} alt='logo' width={90} />}
        isRtl={true}
      >
        <StepContext.Consumer>
          {({ step }): JSX.Element | null => {
            return step === -1 ? (
              <CreditReject />
            ) : step === 1 ? (
              <UserPersonalInfo />
            ) : step === 2 ? (
              <OTP />
            ) : step === 3 ? (
              <CreditInfo />
            ) : step === 4 ? (
              <BusinessInfo />
            ) : step === 5 ? (
              <BusinessInfoSecond />
            ) : step === 6 ? (
              <BusinessAdditionInfo />
            ) : step === 7 ? (
              <ProcessCompleted />
            ) : null
          }}
        </StepContext.Consumer>
      </DialogWrapper>
    </JourneyContext.Provider>
  )
}

export default Home
