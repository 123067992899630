import axios from 'axios'
import { AUTH_SERVICE_URL, CLIENT_ID } from '../../consts'

const verifyOtpFromSms = async (phoneNumber: string, otpCode: string): Promise<any> => {
  const response = await axios.post(`${AUTH_SERVICE_URL}/verify-otp `, { phoneNumber, otp: otpCode, clientId: CLIENT_ID })

  return response.data
}

export default verifyOtpFromSms
