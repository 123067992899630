import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import Button from '../generic/Button'
import Container from '../generic/Container/Container'
import CreditRejectLogic from './CreditRejectLogic'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignSelf: 'center',
    flex: 1,
    textAlign: 'center'
  }
}), { name: 'CreditReject' })
const CreditReject = (): JSX.Element => {
  const classes = useStyles()

  const { handleClose } = CreditRejectLogic()

  return (
    <Container>
      <Typography variant='h5' fontWeight='bold' alignSelf='center' textAlign='center' padding={3}>
        תודה שבחרת לפנות למיכמן.
      </Typography>
      <div className={classes.container}>
        <Typography>
          לצערנו בשלב זה ובהתאם לנתונים הידועים לנו, לא קיימת לך הזכאות לקבלת הלוואה.
          <br />
          הינכם מוזמנים ליצור קשר עם אחד מנציגנו כדי לבחון אפשרויות מימון נוספות.
          <br />
          צוות מיכמן
        </Typography>
        <div>
          <Button title='סיום' onClick={handleClose} />
        </div>
      </div>
    </Container >
  )
}

export default CreditReject