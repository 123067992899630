/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { StepContext } from '@open-finance-ai/components'
import React, { useContext, useEffect } from 'react'
import toast from 'react-hot-toast'
import LogRocket from 'logrocket'
import { JourneyContext } from '../../store/JourneyContext/JourneyContext'

function UserPersonalInfoFormLogic() {
  const {
    setStep,
  } = useContext(StepContext)
  
  const {
    firstName,
    lastName,
    email,
    phone,
    setIsLoading,
  } = useContext(JourneyContext)


  const handleContinue = (e: React.FormEvent): void => {
    e.preventDefault()
    try {
      LogRocket.identify(phone, {
        name: `${firstName} ${lastName}`,
        email,
      })

      setStep((step) => step + 1)
    } catch (e) {
      toast.error('שגיאה לא ידועה, לא ניתן להמשיך בתהליך כרגע')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(()=> setStep(1), [setStep])

  return {
    handleContinue,
  }
}

export default UserPersonalInfoFormLogic
