/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { StepContext } from '@open-finance-ai/components'
import React, { useContext } from 'react'
import { MenuItem } from '@mui/material'
import toast from 'react-hot-toast'
import { JourneyContext } from '../../store/JourneyContext/JourneyContext'
import { updateLead } from '../../api'

function BusinessAdditionInfoLogic() {
  const {
    increment,
  } = useContext(StepContext)

  const {
    leadId,
    lastYearIncome,
    predictedCurrentYearIncome,
    businessEstablishedDate,
    isBankrupt,
    hasFailedCheques,
    agreeToTerms,
    approveMailinglist,
    setIsLoading,
    accessToken,
    creditType,
  } = useContext(JourneyContext)

  const creditTypes = [
    'ניכיון שיקים',
    'הלוואה ללא בטוחה',
    'הלוואה עם בטוחה',
    'נדל״ן',
  ]

  const renderCreditType = creditTypes.map((type) => (
    <MenuItem
      value={type}
      key={type}
    > {type}
    </MenuItem>
  ))


  const handleContinue = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()
    try {
      setIsLoading(true)

      const minimumIncome = 5000000
      const twoYearFromNow = new Date()

      twoYearFromNow.setFullYear(twoYearFromNow.getFullYear() - 2)

      const approvedInitialConditions = (creditType === 'cheques') ||
        (!isBankrupt
          && !hasFailedCheques
          && lastYearIncome >= minimumIncome
          && predictedCurrentYearIncome >= minimumIncome
          && businessEstablishedDate <= twoYearFromNow)

      const status = approvedInitialConditions ? 'טרם נבדק' : 'נדחה בגלל תנאי סף'

      const updatedLead = {
        isBankrupt,
        hasFailedCheques,
        agreeToTerms,
        approveMailinglist,
        status
      } as Record<string, any>

      const isSuccess = await updateLead(leadId, accessToken, updatedLead)

      if (!isSuccess) {
        throw new Error()
      }

      increment()
    } catch (e) {
      toast.error('שגיאה לא ידועה, לא ניתן להמשיך בתהליך כרגע')
    } finally {
      setIsLoading(false)
    }
  }

  return {
    renderCreditType,
    handleContinue,
  }
}

export default BusinessAdditionInfoLogic