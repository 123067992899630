import axios from 'axios'
import { UpdateLead } from '../../@types'
import { LOAN_SERVICE_URL } from '../../consts'

const updateLead: UpdateLead = async (leadId, accessToken, updateLead) => {

  const response = await axios.patch(`${LOAN_SERVICE_URL}/lead/${leadId} `, {
    ...updateLead,
  }, {
    headers: {
      authorization: `Bearer ${accessToken}`
    }
  })

  if (response.status !== 200) {
    throw new Error('lead was not updated')
  }

  const { success } = response.data

  return success
}

export default updateLead
