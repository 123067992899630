import React from 'react'
import { makeStyles } from '@mui/styles'
import { FormHelperText, Theme, Typography } from '@mui/material'
import OtpInput from 'react-otp-input'
import otpImage from '../../assets/images/otp.jpeg'
import Button from '../generic/Button'
import Container from '../generic/Container/Container'
import OTPLogic from './OTPLogic'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center'
  },
  title: {
    padding: spacing(2),
    letterSpacing: 0.2,
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  otpContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: spacing(3),
    direction: 'ltr',
  },
  otp: {
    height: 50,
    width: '45px !important',
    background: 'rgb(203 203 203 / 30%);',
    borderRadius: 5,
    borderWidth: 0,
    color: 'black',
    fontSize: 20,
    fontWeight: 'lighter',
  },
  resendCodeMessage: {
    fontSize: 12,
  },
  resendCodeContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  resendCodeBtn: {
    paddingTop: spacing(),
    paddingButton: spacing(3),
    '&:hover': {
      color: 'blue'
    }
  },
  errorMessage: {
    display: 'flex',
    textAlign: 'right',
    fontSize: 11
  },
  backBtn: {
    backgroundColor: 'grey',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    placeContent: 'space-between',
  },
}), { name: 'OTP' })

const otpLength = 6

const OTP = (): JSX.Element => {
  const classes = useStyles()

  const {
    otp,
    error,
    setOtp,
    handleSendOTP,
    handleSubmitOTP,
    isLoading,
  } = OTPLogic()

  return (
    <Container>
      <form
        className={classes.form}
        onSubmit={handleSubmitOTP}
        onInvalid={({ target }) => (target as HTMLInputElement).setCustomValidity('אנא מלא את השדה הזה')}
        onInput={({ target }) => (target as HTMLInputElement).setCustomValidity('')}
      >
        <div className={classes.imageContainer}>
          <img src={otpImage} alt='img' width='250' height='150' />
        </div>
        <div>
          <Typography className={classes.title}>
            הכנס את הקוד שנשלח אליך בהודעה
          </Typography>
          <OtpInput
            value={otp}
            onChange={(otp: string) => setOtp(otp)}
            numInputs={otpLength}
            separator={<span style={{ padding: 6 }}></span>}
            containerStyle={classes.otpContainer}
            inputStyle={classes.otp}
            shouldAutoFocus={true}
            isInputNum={true}
          />
          <div style={{ height: 20 }}>
            <FormHelperText error className={classes.errorMessage}>
              {error}
            </FormHelperText>
          </div>
        </div>
        <div>
          <Typography textAlign='center' sx={{ fontSize: 14, color: 'gray' }}>
            לא קבלת את הקוד?
          </Typography>
          <div className={classes.resendCodeContainer}>
            <Typography
              onClick={handleSendOTP} className={classes.resendCodeBtn}
              sx={{ fontSize: 14, textDecoration: 'underline', cursor: 'pointer' }}
            >
              לחץ כאן לשליחה מחדש
            </Typography>
          </div>
        </div>
        <div>
          <Button title='המשך' type='submit' isLoading={isLoading} />
        </div>

      </form>
    </Container>
  )
}

export default OTP
