import { FormControl, InputAdornment, InputLabel, Select, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useContext } from 'react'
import { JourneyContext } from '../../store/JourneyContext/JourneyContext'
import Button from '../generic/Button'
import Container from '../generic/Container/Container'
import CustomSlider from '../generic/Slider/Slider'
import handleInputWithRestrictions from '../../helpers/restrictUnwantedInputs'
import CreditInfoLogic from './CreditInfoLogic'

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    placeContent: 'space-between',
  },
  textFieldNumber: {
    '& .MuiOutlinedInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
}), { name: 'CreditInfo' })

const CreditInfo = (): JSX.Element => {
  const classes = useStyles()

  const {
    creditType, setCreditType,
    collateralType, setCollateralType,
    otherCollateralType, setOtherCollateralType,
    creditPurpose, setCreditPurpose,
    creditRequested, setCreditRequested,
    creditTerm, setCreditTerm,
    isLoading,
  } = useContext(JourneyContext)

  const {
    renderCreditType,
    renderCollateralTypes,
    renderCreditPurpose,
    handleContinue,
    getMonthValue,
    creditTermsSliderMarks,
  } = CreditInfoLogic()

  return (
    <Container>
      <Typography variant='h5' fontWeight='bold' alignSelf='center' padding={3}>
        פרטי האשראי המבוקש
      </Typography>
      <form
        className={classes.form}
        onSubmit={handleContinue}
        onInvalid={({ target }) => (target as HTMLInputElement).setCustomValidity('אנא מלא את השדה הזה')}
        onInput={({ target }) => (target as HTMLInputElement).setCustomValidity('')}
        onSelect={({ target }) => (target as HTMLInputElement).setCustomValidity('')}
      >
        <div>
          <FormControl fullWidth margin='dense'>
            <InputLabel id="credit-type-select-label" required>סוג האשראי המבוקש</InputLabel>
            <Select
              labelId="credit-type-select-label"
              id="credit-type-select"
              value={creditType}
              label="סוג האשראי המבוקש"
              onChange={({ target }) => setCreditType(target.value)}
              required
            >
              {renderCreditType}
            </Select>
          </FormControl>
          {(creditType && creditType === 'collateral') &&
            <FormControl fullWidth margin='dense'>
              <InputLabel id="insure-type-select-label" required>סוג הבטוחה</InputLabel>
              <Select
                labelId="insure-type-select-label"
                id="insure-type-select"
                value={collateralType}
                label="סוג הבטוחה"
                onChange={({ target }) => setCollateralType(target.value)}
                required
              >
                {renderCollateralTypes}
              </Select>
            </FormControl>}
          {(collateralType && collateralType === 'other') &&
            <TextField
              label='בטוחה נוספת'
              value={otherCollateralType}
              onChange={({ target }) => handleInputWithRestrictions(target.value, setOtherCollateralType)}
              required
              fullWidth
              margin='dense'
              autoComplete='off'
              className={classes.textFieldNumber}
            />}
          {(creditType && creditType !== 'cheques') &&
            <>
              <FormControl fullWidth margin='dense'>
                <InputLabel id="credit-purpose-select-label" required>מטרת האשראי</InputLabel>
                <Select
                  labelId="credit-purpose-select-label"
                  id="credit-purpose-select"
                  value={creditPurpose}
                  label="מטרת האשראי"
                  onChange={({ target }) => setCreditPurpose(target.value)}
                  required
                >
                  {renderCreditPurpose}
                </Select>
              </FormControl>
              <TextField
                label='סכום האשראי המבוקש'
                placeholder='מינימום 150,000₪'
                value={Number(creditRequested).toLocaleString()}
                onChange={({ target }) => !isNaN(+target.value.replace(/,/g, ''))
                  ? handleInputWithRestrictions(target.value.replace(/,/g, ''), setCreditRequested)
                  : undefined}
                required
                fullWidth
                margin='dense'
                autoComplete='off'
                className={classes.textFieldNumber}
                InputProps={{
                  startAdornment: (<InputAdornment position="end">₪</InputAdornment>),
                  // <Typography sx={{ paddingRight: 0 }}>₪ </Typography>),
                }}
              />
              <div>
                <Typography textAlign='right' sx={{ marginBottom: 4 }}>
                  תקופת האשראי
                </Typography>
                <div style={{ padding: '0px 20px' }}>
                  <CustomSlider
                    value={creditTerm}
                    onChange={(e, newValue) => setCreditTerm(newValue)}
                    getAriaValueText={getMonthValue}
                    valueLabelFormat={getMonthValue}
                    valueLabelDisplay="on"
                    step={1}
                    min={1}
                    max={(collateralType && collateralType === 'realEstate') ? 60 : 36}
                    marks={creditTermsSliderMarks}
                  />
                </div>
              </div>
            </>}
        </div>
        <div>
          <Button title='המשך' type='submit' isLoading={isLoading} />
        </div>
      </form>
    </Container>
  )
}

export default CreditInfo