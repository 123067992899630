/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useState } from 'react'

const JourneyContextLogic = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [leadId, setLeadId] = useState('')

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')

  const [creditType, setCreditType] = useState('')
  const [collateralType, setCollateralType] = useState('')
  const [otherCollateralType, setOtherCollateralType] = useState('')
  const [creditRequested, setCreditRequested] = useState('')
  const [creditTerm, setCreditTerm] = useState(1)
  const [creditPurpose, setCreditPurpose] = useState('')

  const [businessType, setBusinessType] = useState('')
  const [businessId, setBusinessId] = useState('')
  const [nationalId, setNationalId] = useState('')
  const [businessName, setBusinessName] = useState('')
  const [predictedCurrentYearIncome, setPredictedCurrentYearIncome] = useState('')
  const [lastYearIncome, setLastYearIncome] = useState('')
  const [businessEstablishedDate, setEstablishmentDate] = useState(null)

  const [isBankrupt, setIsBankrupt] = useState(false)
  const [hasFailedCheques, setHasFailedCheques] = useState(false)
  const [agreeToTerms, setAgreeToTerms] = useState(false)
  const [approveMailinglist, setApproveMailinglist] = useState(true)
  const [activityArea, setActivityArea] = useState('')
  const [geoArea, setGeoArea] = useState('')
  const [accessToken, setAccessToken] = useState('')

  const JourneyContextValue = {
    isLoading, setIsLoading,
    leadId, setLeadId,

    firstName, setFirstName,
    lastName, setLastName,
    phone, setPhone,
    email, setEmail,

    creditType, setCreditType,
    collateralType, setCollateralType,
    otherCollateralType, setOtherCollateralType,
    creditRequested, setCreditRequested,
    creditTerm, setCreditTerm,
    creditPurpose, setCreditPurpose,

    businessType, setBusinessType,
    businessId, setBusinessId,
    nationalId, setNationalId,
    businessName, setBusinessName,
    predictedCurrentYearIncome, setPredictedCurrentYearIncome,
    lastYearIncome, setLastYearIncome,
    businessEstablishedDate, setEstablishmentDate,

    accessToken, setAccessToken,

    isBankrupt, setIsBankrupt,
    hasFailedCheques, setHasFailedCheques,
    agreeToTerms, setAgreeToTerms,
    approveMailinglist, setApproveMailinglist,
    activityArea, setActivityArea,
    geoArea, setGeoArea,
  }

  return {
    JourneyContextValue,
  }
}

export default JourneyContextLogic