/* eslint-disable max-len */
import { Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useContext } from 'react'
import { JourneyContext } from '../../store/JourneyContext/JourneyContext'
import Button from '../generic/Button'
import Container from '../generic/Container/Container'
import BusinessAdditionInfoLogic from './BusinessAdditionInfoLogic'

const useStyles = makeStyles(
  () => ({
    form: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      width: '100%',
      placeContent: 'space-between',
    },
  }),
  { name: 'BusinessAdditionInfo' }
)

const BusinessAdditionInfo = (): JSX.Element => {
  const classes = useStyles()

  const {
    isBankrupt,
    setIsBankrupt,
    hasFailedCheques,
    setHasFailedCheques,
    agreeToTerms,
    setAgreeToTerms,
    approveMailinglist,
    setApproveMailinglist,
    isLoading,
  } = useContext(JourneyContext)

  const { handleContinue } = BusinessAdditionInfoLogic()

  return (
    <Container>
      <Typography variant='h5' fontWeight='bold' alignSelf='center' padding={3}>
        פרטים נוספים
      </Typography>
      <form
        className={classes.form}
        onSubmit={handleContinue}
        onInvalid={({ target }) => (target as HTMLInputElement).setCustomValidity('אנא מלא את השדה הזה')}
        onInput={({ target }) => (target as HTMLInputElement).setCustomValidity('')}
        onSelect={({ target }) => (target as HTMLInputElement).setCustomValidity('')}
      >
        <div>
          <FormControl margin='dense'>
            <FormLabel id='is-business-bankruptcy-radio-group-label' sx={{ color: 'black' }}>
              האם בית העסק נמצא או היה בתהליך של הוצאה לפועל או פשיטת רגל במהלך 36 החודשים האחרונים?{' '}
            </FormLabel>
            <RadioGroup
              aria-labelledby='is-business-bankruptcy-radio-group-label'
              value={isBankrupt}
              onChange={({ target }) => setIsBankrupt(target.value)}
              row
            >
              <FormControlLabel value={true} control={<Radio required />} label='כן' />
              <FormControlLabel value={false} control={<Radio required />} label='לא' />
            </RadioGroup>
          </FormControl>
          <FormControl margin='dense'>
            <FormLabel id='is-returns-checks-radio-group-label' sx={{ color: 'black' }}>
              האם היו שיקים שחזרו מסיבת אכ״מ במהלך 36 החודשים האחרונים?
            </FormLabel>
            <RadioGroup
              aria-labelledby='is-returns-checks-radio-group-label'
              value={hasFailedCheques}
              onChange={({ target }) => setHasFailedCheques(target.value)}
              row
            >
              <FormControlLabel value={true} control={<Radio required />} label='כן' />
              <FormControlLabel value={false} control={<Radio required />} label='לא' />
            </RadioGroup>
          </FormControl>
        </div>
        <div>
          <FormControl margin='dense'>
            <Typography fontSize={12}>
              ◦ ידוע לי כי מתן האשראי כפוף להחלטת מיכמן בס״ד בע״מ ולשיקולה הבלעדי ובהתאם למדיניותה.
            </Typography>
            <Typography fontSize={12}>
              ◦ אי עמידה בפרעון האשראי עלול לגרור חיוב בריבית פיגורים והליכי הוצאה לפועל. ט.ל.ח
            </Typography>
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  required
                  sx={{ paddingLeft: 2, width: 14, height: 18 }}
                  checked={agreeToTerms}
                  onChange={({ target }) => {
                    setAgreeToTerms(target.checked)
                  }}
                  onInvalid={({ target }) =>
                    (target as HTMLInputElement).setCustomValidity('אנא סמן את התיבה הזו אם ברצונך להמשיך')
                  }
                  onInput={({ target }) => (target as HTMLInputElement).setCustomValidity('')}
                />
              }
              sx={{ margin: 0 }}
              label={<Typography fontSize={12}>אני מאשר/ת שכל הנתונים לעיל נכונים</Typography>}
            />
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  required
                  sx={{ paddingLeft: 2, width: 14, height: 18 }}
                  checked={approveMailinglist}
                  onChange={({ target }) => {
                    setApproveMailinglist(target.checked)
                  }}
                  onInvalid={({ target }) =>
                    (target as HTMLInputElement).setCustomValidity('אנא סמן את התיבה הזו אם ברצונך להמשיך')
                  }
                  onInput={({ target }) => (target as HTMLInputElement).setCustomValidity('')}
                />
              }
              sx={{ margin: 0 }}
              label={
                <Typography fontSize={12}>
                  הנני מאשר/ת קבלת חומר פרסומי ואני מסכימ/ה{' '}
                  <a href='https://michman-loans.co.il/privacy-policy' target='_blank' rel='noreferrer'>
                    למדיניות הפרטיות
                  </a>
                </Typography>
              }
            />
          </FormControl>
        </div>
        <div>
          <Button title='המשך' type='submit' isLoading={isLoading} />
        </div>
      </form>
    </Container>
  )
}

export default BusinessAdditionInfo
